import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../shared/utilty/main-functions";

const backendUrl = KuikaAppManager.GetBackendUrl();

declare let window: any;
export class LanguageService {
  public static async SaveLanguage(language): Promise<AxiosResponse> {
    try {
      const res = await Axios.put<null, AxiosResponse>(`${backendUrl}/auth/language?language=${language}`);
      return res;
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }
}
