import { combineReducers, createStore } from "redux";
import { iyzicoDrawerReducer, photoTakeReducer, stripeDrawerReducer } from "./photo-take/reducers";
import { spinnerReducer } from "./spinner/reducers";

const rootReducer = combineReducers({
  spinnerReducer,
  photoTakeReducer,
  stripeDrawerReducer,
  iyzicoDrawerReducer
});

export type AppState = ReturnType<typeof rootReducer>;

const globalStore = createStore<any, any, any, any>(rootReducer);
const configureStore = () => globalStore;
export default configureStore;
