import { ApplicationService } from "../services/application-service";

declare let window: any;

const LocalStorageService = (() => {
  let _service: any;
  function _getService(this: {
    getService: () => any;
    setToken: (tokenObj: any) => void;
    getAccessToken: () => string | null;
    getRefreshToken: () => string | null;
    clearToken: () => void;
  }) {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setToken(tokenObj: any) {
    localStorage.setItem("tokenString", tokenObj.tokenString);
    localStorage.setItem("refreshToken", tokenObj.refreshToken);
  }

  function _setVerificationStatus(status: any) {
    localStorage.setItem("isVerificationValid", status);
  }
  function _getVerificationStatus() {
    return localStorage.getItem("isVerificationValid");
  }

  function _setResetPasswordStatus(status) {
    localStorage.setItem("reset_pw_status", status);
  }

  function _getResetPasswordStatus() {
    return localStorage.getItem("reset_pw_status");
  }

  function _setLoginStatus(status: any) {
    localStorage.setItem("login_status", status);
  }
  function _getLoginStatus() {
    return localStorage.getItem("login_status");
  }
  function _setUserLanguage(tokenObj: any) {
    if (tokenObj.language) {
      localStorage.setItem("ml", tokenObj.language);
    }
  }
  function _setForgotPasswordEmail(email: string) {
    localStorage.setItem("forgotPasswordEmail", email);
  }
  function _setCurrentDomain(domainName: string) {
    // localStorage.setItem("currentDomain", domainName);
    window.currentDomain = domainName;
    // window.currentDomain = "Kuika";
  }
  function _getForgotPasswordEmail() {
    return localStorage.getItem("forgotPasswordEmail");
  }
  function _getAccessToken() {
    return localStorage.getItem("tokenString");
  }
  function _getRefreshToken() {
    return localStorage.getItem("refreshToken");
  }
  function _getCurrentDomain() {
    // window.currentDomain = "Kuika";
    // return "Kuika";
    return window.currentDomain;
    // return localStorage.getItem("currentDomain");
  }

  function _setVerificationUserName(userName) {
    localStorage.setItem("verificationUserName", userName);
  }

  function _getVerificationUserName() {
    return localStorage.getItem("verificationUserName");
  }

  function _clearSessionStorage() {
    const applications = structuredClone(ApplicationService.getAllApplicationsFromSessionStorageRaw());

    sessionStorage.clear();

    if (applications) {
      sessionStorage.setItem("all-applications", applications.allApplications);
      sessionStorage.setItem("front-end-applications", applications.frontEndApplications);
    }
  }

  function _clearToken() {
    localStorage.removeItem("tokenString");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("forgotPasswordEmail");
    localStorage.removeItem("verificationUserName");
    _clearSessionStorage();
  }

  return {
    getService: _getService,
    setToken: _setToken,
    setCurrentDomain: _setCurrentDomain,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearSessionStorage: _clearSessionStorage,
    clearToken: _clearToken,
    setForgotPasswordEmail: _setForgotPasswordEmail,
    getForgotPasswordEmail: _getForgotPasswordEmail,
    getCurrentDomain: _getCurrentDomain,
    setUserLanguage: _setUserLanguage,
    setLoginStatus: _setLoginStatus,
    getLoginStatus: _getLoginStatus,
    getVerificationStatus: _getVerificationStatus,
    setVerificationStatus: _setVerificationStatus,
    setResetPasswordStatus: _setResetPasswordStatus,
    getResetPasswordStatus: _getResetPasswordStatus,
    setVerificationUserName: _setVerificationUserName,
    getVerificationUserName: _getVerificationUserName
  };
})();
export default LocalStorageService;
