import * as QueryStringify from "querystringify";
import { ScreenInputHelper } from "../utilty/screen-input-helper";

export const withScreenInput = (Component: any) => {
  return (props: any) => {
    const screenInputsByQueryString = {};
    let screenInputsByProp = {};
    let screenInputsByState = {};

    if (props?.location?.search) {
      const obj = QueryStringify.parse(props.location.search);
      const screenName = /[^/]*$/.exec(props.location.pathname)![0];
      for (const key in obj) {
        screenInputsByQueryString[key] = ScreenInputHelper.getScreenInputValue(screenName, key, obj[key]);
      }
    }
    if (props?.screenInputs) {
      screenInputsByProp = props.screenInputs;
    }
    if (props?.location?.state?.screenInputs) {
      screenInputsByState = props.location.state.screenInputs;
    }

    return (
      <>
        <Component
          {...props}
          screenInputs={{ ...screenInputsByProp, ...screenInputsByQueryString, ...screenInputsByState }}
        />
      </>
    );
  };
};
