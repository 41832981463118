import LocalStorageService from "../../auth/local-storage-service";
import HelperService from "../../services/helper-service";
import { msalConfig } from "./msal-auth-config";

export const MsalLogin = (props) => {
  if (LocalStorageService.getAccessToken() !== null) {
    HelperService.redirectGotoStartingScreen();
  } else {
    indexedDB.databases().then((databases) => {
      const exists = databases.some((db) => db.name === "msalDb");
      if (exists) {
        indexedDB.deleteDatabase("msalDb");
        const msalLogoutUrl: string = `${msalConfig.authority}/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}${msalConfig.postLogoutRedirectUri}&client_id=${msalConfig.clientId}`;
        window.location.replace(msalLogoutUrl);
      } else {
        const authorizationRequestUrl: string = `${msalConfig.authority}/oauth2/v2.0/authorize?client_id=${
          msalConfig.clientId
        }&redirect_uri=${window.location.origin + msalConfig.redirectUri}&response_type=code&scope=${msalConfig.scope}`;
        window.location.replace(authorizationRequestUrl);
      }
    });
  }
  return <>Conneting azure active directory, please wait...</>;
};
