import Axios, { AxiosResponse } from "axios";
import { IStripePayment, IStripeSetupIntent } from "../models/dto/stripe-payment";
import {
  IStripeCancelPaymentDto,
  IStripeCaptureDto,
  IStripeChargePaymentMethodDto,
  IStripeConnectedAccountDto,
  IStripeConnectedAccountOnboardingDto,
  IStripeConnectedAccountPayout,
  IStripeConnectedAccountWithdraw,
  IStripeCreateConnectedAccountDto,
  IStripeDeletePaymentMethodDto,
  IStripeListCustomerPaymentMethodsByIdDto,
  IStripeMasterAccountDetailsDto,
  IStripeMasterAccountDto,
  IStripePaymentDto,
  IStripeRefundDto,
  IStripeUpdateCustomerDto
} from "../models/dto/stripe.dto";
import { KuikaAppManager } from "../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../shared/utilty/main-functions";

const backendUrl = KuikaAppManager.GetBackendUrl();

export class StripeService {
  public static async OneTimePayment(dto: IStripePaymentDto): Promise<AxiosResponse<IStripePayment>> {
    try {
      return await Axios.post<IStripePaymentDto, AxiosResponse>(`${backendUrl}/Stripe/onetimepayment`, dto);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async SetupIntent(dto: any): Promise<AxiosResponse<IStripeSetupIntent>> {
    try {
      return await Axios.post<any, AxiosResponse>(`${backendUrl}/Stripe/savePaymentInfo`, dto);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async OneTimePaymentWithDestination(dto: IStripePaymentDto): Promise<AxiosResponse<IStripePayment>> {
    try {
      return await Axios.post<IStripePaymentDto, AxiosResponse>(
        `${backendUrl}/Stripe/onetimepaymentWithDestination`,
        dto
      );
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async RefundPayment(dto: IStripeRefundDto): Promise<AxiosResponse<boolean>> {
    try {
      return await Axios.post<IStripePaymentDto, AxiosResponse>(`${backendUrl}/Stripe/refundStripeCharge`, dto);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async CapturePayment(dto: IStripeCaptureDto): Promise<AxiosResponse<boolean>> {
    try {
      return await Axios.post<IStripePaymentDto, AxiosResponse>(`${backendUrl}/Stripe/captureReservedFunds`, dto);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async ChargePaymentMethod(dto: IStripeChargePaymentMethodDto): Promise<AxiosResponse<boolean>> {
    try {
      return await Axios.post<IStripeChargePaymentMethodDto, AxiosResponse>(
        `${backendUrl}/Stripe/chargePaymentMethod`,
        dto
      );
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async GetMasterAccountBalance(dto: IStripeMasterAccountDto): Promise<AxiosResponse<any>> {
    try {
      return await Axios.post<IStripeMasterAccountDto, AxiosResponse>(
        `${backendUrl}/Stripe/getMasterAccountBalance`,
        dto
      );
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async CancelPaymentIntent(dto: IStripeCancelPaymentDto): Promise<AxiosResponse<boolean>> {
    try {
      return await Axios.post<IStripeCancelPaymentDto, AxiosResponse>(`${backendUrl}/Stripe/cancelPayment`, dto);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async UpdateStripeUserInfo(dto: IStripeUpdateCustomerDto): Promise<AxiosResponse<boolean>> {
    try {
      return await Axios.post<IStripeUpdateCustomerDto, AxiosResponse>(`${backendUrl}/Stripe/updateCustomer`, dto);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async DeletePaymentMethod(dto: IStripeDeletePaymentMethodDto): Promise<AxiosResponse<boolean>> {
    try {
      return await Axios.post<IStripeUpdateCustomerDto, AxiosResponse>(`${backendUrl}/Stripe/deletePaymentMethod`, dto);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async GetCustomerPaymentMethods(): Promise<AxiosResponse<boolean>> {
    try {
      return await Axios.post<any, AxiosResponse>(`${backendUrl}/Stripe/customerPaymentMethods`);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async GetCustomerPaymentMethodsbyId(
    dto: IStripeListCustomerPaymentMethodsByIdDto
  ): Promise<AxiosResponse<boolean>> {
    try {
      return await Axios.post<any, AxiosResponse>(`${backendUrl}/Stripe/customerPaymentMethodsbyId`, dto);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async GetPaymentReceiptBase64(dto: IStripeCancelPaymentDto): Promise<AxiosResponse<string>> {
    try {
      return await Axios.post<IStripeCancelPaymentDto, AxiosResponse>(
        `${backendUrl}/Stripe/getPaymentReceiptBase64`,
        dto
      );
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async GetPaymentReceiptLink(dto: IStripeCancelPaymentDto): Promise<AxiosResponse<string>> {
    try {
      return await Axios.post<IStripeCancelPaymentDto, AxiosResponse>(
        `${backendUrl}/Stripe/getPaymentReceiptLink`,
        dto
      );
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async CheckPaymentIntentStatus(dto: IStripeCancelPaymentDto): Promise<AxiosResponse<boolean>> {
    try {
      return await Axios.post<IStripeCancelPaymentDto, AxiosResponse>(
        `${backendUrl}/Stripe/CheckPaymentIntentStatus`,
        dto
      );
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async GetMasterAccountBalanceHistory(dto: IStripeMasterAccountDetailsDto): Promise<AxiosResponse<any>> {
    try {
      return await Axios.post<IStripeMasterAccountDetailsDto, AxiosResponse>(
        `${backendUrl}/Stripe/getMasterAccountsBalanceHistory`,
        dto
      );
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async GetConnectedAccountBalance(dto: IStripeConnectedAccountDto): Promise<AxiosResponse<any>> {
    try {
      return await Axios.post<IStripeConnectedAccountDto, AxiosResponse>(
        `${backendUrl}/Stripe/getConnectedAccountBalance`,
        dto
      );
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async GetConnectedAccountStatus(dto: IStripeConnectedAccountDto): Promise<AxiosResponse<any>> {
    try {
      return await Axios.post<IStripeConnectedAccountDto, AxiosResponse>(
        `${backendUrl}/Stripe/getConnectedAccountStatus`,
        dto
      );
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async GetConnectedAccountOnboardingLink(
    dto: IStripeConnectedAccountOnboardingDto
  ): Promise<AxiosResponse<any>> {
    try {
      return await Axios.post<IStripeConnectedAccountOnboardingDto, AxiosResponse>(
        `${backendUrl}/Stripe/createAccountOnboardingLink`,
        dto
      );
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async CreateConnectedAccount(dto: IStripeCreateConnectedAccountDto): Promise<AxiosResponse<any>> {
    try {
      return await Axios.post<IStripeCreateConnectedAccountDto, AxiosResponse>(
        `${backendUrl}/Stripe/createConnectedAccount`,
        dto
      );
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async ConnectedAccountWithdraw(dto: IStripeConnectedAccountWithdraw): Promise<AxiosResponse<any>> {
    try {
      return await Axios.post<IStripeCreateConnectedAccountDto, AxiosResponse>(
        `${backendUrl}/Stripe/connectedAcountWithdraw`,
        dto
      );
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async ConnectedAccountPayout(dto: IStripeConnectedAccountPayout): Promise<AxiosResponse<any>> {
    try {
      return await Axios.post<IStripeCreateConnectedAccountDto, AxiosResponse>(
        `${backendUrl}/Stripe/connectedAcountPayout`,
        dto
      );
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }
}
