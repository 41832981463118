import * as React from "react";
import { WorkflowService } from "../services/workflow-service";
import { KMainFunctions } from "../shared/utilty/main-functions";

interface IWFMailInteractionState {
  params: any;
}

interface IWFMailInteractionProps {}

export class WFMailInteraction extends React.PureComponent<IWFMailInteractionProps, IWFMailInteractionState> {
  constructor(props) {
    super(props);
    this.state = {
      params: {}
    };
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    this.setState({ params }, () => {
      this.sendParamsToApi();
    });
  }

  sendParamsToApi = () => {
    const { params } = this.state;

    const queryString = new URLSearchParams(params).toString();
    WorkflowService.SendMailInteraction(queryString).then((response) => {
      if (response.status === 200) {
        KMainFunctions.displaySuccessNotification("The process progressed successfully.");
      } else {
        KMainFunctions.displayErrorNotification("An error occurred while progressing the process.");
      }

      setTimeout(() => {
        window.close();
      }, 2000);
    });
  };

  render() {
    return <></>;
  }
}
