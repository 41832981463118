export const SHOW_SPINNER = "SHOW_SPINNER";
export const HIDE_SPINNER = "HIDE_SPINNER";

export interface SpinnerState {
  spinnerCount: number;
}

interface ShowSpinnerAction {
  type: typeof SHOW_SPINNER;
  meta: {
    params: {};
  };
}

interface HideSpinnerAction {
  type: typeof HIDE_SPINNER;
  meta: {
    params: {};
  };
}

export type SpinnerActionTypes = ShowSpinnerAction | HideSpinnerAction;
