import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../shared/utilty/main-functions";

const backendUrl = KuikaAppManager.GetBackendUrl();

class ServerFilesService {
  public static async DownloadFile(filePath: string, fileId: string): Promise<AxiosResponse<string>> {
    const PreviewPath = "/home/UploadedFiles";
    const isKuikaDomain = window.location.href.includes("kuika.com");
    const data = {
      filePath: isKuikaDomain ? PreviewPath : filePath, // Update with the actual file path
      fileId // Update with the actual file name
    };
    try {
      const res = await Axios.post<null, AxiosResponse>(`${backendUrl}/resource/download`, data, {
        responseType: "blob" // important for handling binary data
      });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.fileId); // Set the file name here
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      return res;
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async DeleteFile(filePath: string, fileId: string): Promise<AxiosResponse<string>> {
    const PreviewPath = "/home/UploadedFiles";
    const isKuikaDomain = window.location.href.includes("kuika.com");
    const data = {
      filePath: isKuikaDomain ? PreviewPath : filePath, // Update with the actual file path
      fileId // Update with the actual file name
    };
    try {
      const res = await Axios.post<null, AxiosResponse>(`${backendUrl}/resource/delete`, data);
      return res.data;
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }
}

export default ServerFilesService;
