import LocalStorageService from "../../auth/local-storage-service";
import { EExternalTokenProviderTypes, IExternalTokenRequest } from "../../models/dto/login.dto";
import HelperService from "../../services/helper-service";
import { UserService } from "../../services/user-service";
import { msalConfig } from "./msal-auth-config";

export const MsalRedirect = (props) => {
  const loginWithMsal = (codeParam: string) => {
    const externalTokenRequest: IExternalTokenRequest = {
      providerType: EExternalTokenProviderTypes.AzureAD,
      refreshToken: "",
      tokenString: "",
      username: "",
      redirectUri: window.location.origin + msalConfig.redirectUri,
      externalParameters: { code: codeParam }
    };
    UserService.LoginWithExternalToken(externalTokenRequest).then(
      (res) => {
        if (res && res.status === 200) {
          HelperService.redirectGotoStartingScreen();
        }
      },
      (error) => {
        if (error.response?.data?.message) {
          alert(error.response.data.message);
        }
      }
    );
  };

  if (LocalStorageService.getAccessToken() !== null) {
    HelperService.redirectGotoStartingScreen();
    return <></>;
  }

  if (window.location?.search?.length > 1) {
    var codeParam = new URLSearchParams(window.location?.search).get("code");
    if (codeParam && codeParam.length > 0) {
      loginWithMsal(codeParam);
    }
  }

  return <>Please wait...</>;
};
