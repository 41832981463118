import Axios, { AxiosResponse } from "axios";
import { IIyzicoCheckoutFormDto, IIyzicoCheckoutFormResponseDto } from "../models/dto/iyzico.dto";
import { KuikaAppManager } from "../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../shared/utilty/main-functions";

const backendUrl = KuikaAppManager.GetBackendUrl();

export class IyzicoService {
  public static async CheckoutForm(
    dto: IIyzicoCheckoutFormDto
  ): Promise<AxiosResponse<IIyzicoCheckoutFormResponseDto>> {
    try {
      return await Axios.post<IIyzicoCheckoutFormDto, AxiosResponse>(`${backendUrl}/Iyzico/checkoutform`, dto);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }
}
