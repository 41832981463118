import { EExternalTokenProviderTypes, IExternalTokenRequest } from "../../models/dto/login.dto";
import { UserService } from "../../services/user-service";
import { withHistory } from "../../shared/hoc/with-history";

let kuikaRestApiCalled = false;
const MfeLogin = (props) => {
  if (kuikaRestApiCalled === false) {
    const { external_access_token } = props;
    const { external_refresh_token } = props;
    let { external_user } = props;

    if (external_user === undefined) {
      external_user = "";
    }

    if (
      external_access_token !== null &&
      external_access_token !== undefined &&
      external_access_token !== "" &&
      external_refresh_token !== null &&
      external_refresh_token !== undefined &&
      external_refresh_token !== ""
    ) {
      const externalTokenRequest: IExternalTokenRequest = {
        providerType: EExternalTokenProviderTypes.None,
        refreshToken: external_refresh_token,
        tokenString: external_access_token,
        username: external_user
      };

      kuikaRestApiCalled = true;
      UserService.LoginWithExternalToken(externalTokenRequest).then(
        (res) => {
          if (res && res.status === 200) {
            if (props?.from?.pathname?.length > 0) {
              props.navigator.gotoUrl(props.from.pathname);
            } else {
              props.navigator.gotoStartingScreen();
            }
          }
        },
        (error) => {
          if (error.response?.data?.message) {
            alert(error.response.data.message);
            window.location.reload();
          }
        }
      );
    }
  }
  return <>Conneting to external login service, please wait...</>;
};

const mfeLogin = withHistory(MfeLogin);
export { mfeLogin as MfeLogin };
