import { HIDE_SPINNER, SHOW_SPINNER, SpinnerActionTypes, SpinnerState } from "./types";

const initialState: SpinnerState = {
  spinnerCount: 0
};

export const spinnerReducer = (state = initialState, action: SpinnerActionTypes): SpinnerState => {
  switch (action.type) {
    case SHOW_SPINNER:
      return {
        spinnerCount: state.spinnerCount + 1
      };
    case HIDE_SPINNER:
      let count = state.spinnerCount;
      if (count > 0) count--;
      return {
        spinnerCount: count
      };
    default:
      return state;
  }
};
