export class KComponentFunctions {
  static isButtonElement = (element: Element): boolean => {
    if (element && element.nodeName && element.nodeName.toLowerCase() === "button") {
      return true;
    }
    if (element && element.parentElement) {
      return KComponentFunctions.isButtonElement(element.parentElement);
    }
    return false;
  };

  static isImgElement = (element: Element): boolean => {
    if (element && element.nodeName && element.nodeName.toLowerCase() === "img") {
      return true;
    }
    return false;
  };
}
