import * as _ from "lodash";
import { ELanguage } from "../models/enum/language";
import translations from "../shared/json/translations.json";

class TranslationService {
  private readonly translations = translations;

  private readonly fallbackLanguage: ELanguage = ELanguage.EN;

  private _translate(key: string, language?: ELanguage): string {
    const languageToBeUsed: string = language || localStorage.getItem("ml") || this.fallbackLanguage;

    return TranslationService.ensureString(
      key,
      _.get(
        this.translations,
        `${languageToBeUsed}.${key}`,
        this.fallbackLanguage !== languageToBeUsed ? this.translate(key, this.fallbackLanguage) : key
      )
    );
  }

  public translate(key: string, language?: ELanguage, values?: Record<string, string>): string {
    if (!values) {
      return this._translate(key, language);
    }

    return TranslationService.interpolate(this._translate(key, language), values);
  }

  public static interpolate(text: string, values: Record<string, string>): string {
    const regex = /{{\s?(\w+)\s?}}/g;
    return text.replace(regex, (match, key) => values[key]);
  }

  public static ensureString(key: string, value: unknown): string {
    if (typeof value === "string") {
      return value;
    }

    return key;
  }
}

export default TranslationService;
