import { Button, Form, Input } from "antd";
import * as React from "react";
import { UserService } from "../../services/user-service";
import { KNavigator, withHistory } from "../../shared/hoc/with-history";
import { KMainFunctions } from "../../shared/utilty/main-functions";

interface IForgotPasswordState {
  forgotPasswordErrorMessage: string;
  isCheckYourMailOpen: boolean;
  isLoading: boolean;
}

interface IForgotPasswordProps {
  navigator: KNavigator;
}

class ForgotPassword extends React.PureComponent<IForgotPasswordProps, IForgotPasswordState> {
  constructor(props) {
    super(props);
    this.state = { forgotPasswordErrorMessage: "", isCheckYourMailOpen: false, isLoading: false };
  }

  goToSignIn = () => {
    this.props.navigator.gotoLogin();
  };

  onSubmit = async (data: any) => {
    this.setState({ isLoading: true });
    try {
      UserService.ForgotPassword(data).then(
        (res) => {
          if (res && res.status === 200) {
            this.setState({ forgotPasswordErrorMessage: "", isCheckYourMailOpen: true, isLoading: false });
            alert("Your password reset request has been received. We will send you an email if your email is correct.");
          }
        },
        (error) => {
          if (error.response.data.message) {
            this.setState({
              forgotPasswordErrorMessage: error.response.data.message,
              isLoading: false
            });
          }
        }
      );
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
    }
  };

  render() {
    return (
      <div className="kuika__registration">
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div className="registration-logo">
            <svg width="100" height="29" viewBox="0 0 100 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.4979 0C26.93 0 28.8288 1.95313 28.8288 4.32144V24.5074C28.8288 26.9347 26.93 28.8288 24.4979 28.8288H4.32958C1.89818 28.8288 0 26.8757 0 24.5074V4.32144C0 1.89411 1.95652 0 4.32958 0H24.4979Z"
                fill="#246EFF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.2613 5.87446C11.8972 5.52883 12.4953 5.354 13.0389 5.354C13.4411 5.354 13.7449 5.44895 13.9626 5.5391L14.1519 5.62395L14.3746 5.73456C14.9329 6.03648 15.3123 6.44864 15.5049 6.96227L15.5671 7.12124C15.6441 7.33771 15.7275 7.71784 15.5082 8.24031C15.3356 8.65189 14.9085 9.1052 14.5646 9.43731L14.1625 9.81635L13.9435 10.0354C12.7527 11.2392 11.366 12.7155 11.3882 14.4182C11.3681 15.9523 12.5014 17.3066 13.5993 18.4422L14.112 18.9637L14.5649 19.3919C14.9085 19.7242 15.3356 20.1771 15.5082 20.5887C15.7275 21.1111 15.6441 21.4913 15.5671 21.7077L15.5049 21.8674L15.4339 22.0345C15.2003 22.5245 14.78 22.9131 14.1832 23.191L13.9623 23.2902C13.7445 23.38 13.4409 23.475 13.0382 23.475C12.4953 23.475 11.8966 23.3002 11.2613 22.9552C8.32678 21.3623 6.14385 18.9402 5.0793 17.2053C4.49121 16.2448 4.14566 15.5194 4.12439 14.6519L4.11816 14.4972L4.11903 14.3083L4.13817 13.9444C4.21049 13.1763 4.54467 12.4969 5.0793 11.6243C6.14385 9.88877 8.32612 7.46733 11.2613 5.87446ZM14.0014 11.5167C14.6411 10.659 16.9099 7.82505 18.9437 7.82505C19.0117 7.82505 19.077 7.82783 19.1402 7.83618C21.2425 8.11234 23.234 10.3244 24.0608 11.7441C25.4027 14.0502 24.2768 16.029 24.087 16.3331L23.9486 16.5247C23.4104 17.2351 21.0692 20.1583 18.9689 20.1798L18.8456 20.1771L18.7642 20.1666C16.5212 19.6915 14.5419 17.597 13.826 16.3665C12.4847 14.0611 13.6106 12.0982 13.801 11.7967L14.0014 11.5167Z"
                fill="white"
              />
              <path
                d="M49.02 22H51.36L45 14.3L50.98 8H48.7L41.1 15.82V8H39.1V22H41.1V18.38L43.66 15.78L49.02 22ZM61.1534 11.4V16.88C61.1534 19.16 59.8734 20.4 57.8734 20.4C56.0534 20.4 55.0134 19.36 55.0134 17.28V11.4H53.0934V17.5C53.0934 20.62 54.9134 22.12 57.6534 22.12C59.1534 22.12 60.4734 21.5 61.2534 20.4V22H63.0734V11.4H61.1534ZM68.0386 9.36C68.7986 9.36 69.3386 8.8 69.3386 8.08C69.3386 7.4 68.7786 6.86 68.0386 6.86C67.2986 6.86 66.7386 7.42 66.7386 8.12C66.7386 8.82 67.2986 9.36 68.0386 9.36ZM67.0786 22H68.9986V11.4H67.0786V22ZM81.1145 22H83.4745L78.5745 15.78L83.0345 11.4H80.7145L74.9345 16.68V7.16H73.0145V22H74.9345V19.1L77.1345 17.06L81.1145 22ZM89.3534 11.3C87.6534 11.3 86.0734 11.78 84.9734 12.66L85.7734 14.1C86.5934 13.4 87.8734 12.96 89.1334 12.96C91.0334 12.96 91.9734 13.9 91.9734 15.52V15.9H88.9334C85.7734 15.9 84.6734 17.3 84.6734 19C84.6734 20.84 86.1934 22.12 88.5934 22.12C90.2534 22.12 91.4334 21.56 92.0734 20.6V22H93.8934V15.6C93.8934 12.7 92.2534 11.3 89.3534 11.3ZM88.9134 20.64C87.4534 20.64 86.5734 19.98 86.5734 18.92C86.5734 18.02 87.1134 17.28 89.0134 17.28H91.9734V18.76C91.4934 19.98 90.3534 20.64 88.9134 20.64Z"
                fill="#081F36"
              />
            </svg>
          </div>
          {this.state.isCheckYourMailOpen ? (
            <div className="registration-content">
              <div className="registration-container">
                <div
                  style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}
                >
                  <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M70 77H14C10.1342 77 7 73.8658 7 70V33.3953C7 31.1098 8.11475 28.9713 9.9855 27.6623L42 5.25L74.0145 27.6623C75.8852 28.9713 77 31.1098 77 33.3953V70C77 73.8658 73.8658 77 70 77Z"
                      fill="#78909C"
                    />
                    <path d="M21 24.5H63V63H21V24.5Z" fill="white" />
                    <path
                      d="M70 77H14C10.1342 77 7 73.8657 7 70V35L42 57.75L77 35V70C77 73.8657 73.8658 77 70 77Z"
                      fill="#CFD8DC"
                    />
                    <path
                      d="M26.25 33.25H57.75V36.75H26.25V33.25ZM26.25 40.25H47.25V43.75H26.25V40.25Z"
                      fill="#2196F3"
                    />
                  </svg>
                  <span className="registration-header" style={{ marginTop: 24 }}>
                    Check your email
                  </span>
                  <span className="registration-text-grey" style={{ padding: "0px 24px", textAlign: "center" }}>
                    We have sent a password recover instructions to your email.
                  </span>
                  <div style={{ fontWeight: 500, paddingTop: 36 }} className="k_Bold-Primary-Center">
                    <span className="registration-text" onClick={() => this.goToSignIn()}>
                      Return sign in
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="registration-content">
              <span className="registration-header">Reset your password</span>
              <div className="registration-container">
                <Form name="normal_login" className="login-form" onFinish={this.onSubmit}>
                  <Form.Item
                    name="Email"
                    rules={[{ required: true, message: "Please enter your email!" }]}
                    style={{ display: "block" }}
                  >
                    <Input
                      className="registraionSvgIconsColor"
                      prefix={
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8.56746 12.6756C6.77892 12.6756 5.32422 11.0267 5.32422 8.99989C5.32422 6.97308 6.77892 5.32422 8.56746 5.32422C10.356 5.32422 11.8107 6.97308 11.8107 8.99989C11.8107 11.0267 10.356 12.6756 8.56746 12.6756Z"
                            stroke="#818C99"
                            strokeWidth="1.4"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.8108 5.32432V10.0811C11.8108 11.5142 12.9723 12.6757 14.4054 12.6757C15.8385 12.6757 17 11.5142 17 10.0811V9C17 4.58184 13.4182 1 9 1C4.58184 1 1 4.58184 1 9C1 13.4182 4.58184 17 9 17C9.98897 17 10.9364 16.8205 11.8108 16.4923"
                            stroke="#818C99"
                            strokeWidth="1.4"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      placeholder="E-Mail"
                    />
                  </Form.Item>
                  {this.state.forgotPasswordErrorMessage !== "" ? (
                    <div className="kuika_registration_error" style={{ marginBottom: 0 }}>
                      {this.state.forgotPasswordErrorMessage}
                    </div>
                  ) : (
                    <div className="kuika_registration_error" style={{ opacity: 0, marginBottom: 0 }}>
                      Error
                    </div>
                  )}
                  <Form.Item style={{ paddingTop: 20, display: "block" }}>
                    <Button
                      className="registration-button"
                      htmlType="submit"
                      size="large"
                      loading={this.state.isLoading}
                    >
                      CONTINUE
                    </Button>
                  </Form.Item>
                  <div style={{ fontWeight: 500 }} className="k_Bold-Primary-Center">
                    <span className="registration-text" onClick={() => this.goToSignIn()}>
                      Return sign in
                    </span>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </div>
        <div className="registration-footer">
          <a
            href="https://www.kuika.com/en/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            className="k_Medium-Primary-Left"
          >
            Privacy
          </a>
          <a
            href="https://www.kuika.com/en/terms"
            target="_blank"
            rel="noopener noreferrer"
            className="k_Medium-Primary-Left"
          >
            Terms
          </a>
        </div>
      </div>
    );
  }
}

const forgotPassword = withHistory(ForgotPassword);
export { forgotPassword as ForgotPassword };
