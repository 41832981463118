export enum CheckResultType {
  Success,
  Warning,
  Error
}

export enum EAccountStatus {
  Valid = 0,
  ForceResetPassword = 1,
  ShouldActivated = 2,
  TwoFactorAuthentication = 3
}
