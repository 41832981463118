import {
  HIDE_IYZICO_DRAWER,
  HIDE_PHOTO_TAKE,
  HIDE_STRIPE_DRAWER,
  IyzicoDrawerActionTypes,
  PhotoTakeActionTypes,
  SHOW_IYZICO_DRAWER,
  SHOW_PHOTO_TAKE,
  SHOW_STRIPE_DRAWER,
  StripeDrawerActionTyples
} from "./types";

export const hidePhotoTake = (): PhotoTakeActionTypes => {
  return {
    type: HIDE_PHOTO_TAKE,
    meta: {
      params: {}
    }
  };
};

export const showPhotoTake = (): PhotoTakeActionTypes => {
  return {
    type: SHOW_PHOTO_TAKE,
    meta: {
      params: {}
    }
  };
};

export const showStripeDrawer = (data, callback, screen, futureUse): StripeDrawerActionTyples => {
  return {
    type: SHOW_STRIPE_DRAWER,
    payload: data,
    callback,
    screen,
    futureUse,
    meta: {
      params: {}
    }
  };
};

export const hideStripeDrawer = (data, callback, screen): StripeDrawerActionTyples => {
  return {
    type: HIDE_STRIPE_DRAWER,
    payload: data,
    callback,
    screen,
    meta: {
      params: {}
    }
  };
};

export const showIyzicoDrawer = (data, callback, screen): IyzicoDrawerActionTypes => {
  return {
    type: SHOW_IYZICO_DRAWER,
    payload: data,
    callback,
    screen,
    meta: {
      params: {}
    }
  };
};

export const hideIyzicoDrawer = (data, callback, screen): IyzicoDrawerActionTypes => {
  return {
    type: HIDE_IYZICO_DRAWER,
    payload: data,
    callback,
    screen,
    meta: {
      params: {}
    }
  };
};

type SpinnerActionType = typeof hidePhotoTake | typeof showPhotoTake;
