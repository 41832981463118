import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../shared/utilty/main-functions";

const backendUrl = KuikaAppManager.GetBackendUrl();

interface ISendPushNotificationToUsernamePayload {
  username: string;
  title: string;
  message: string;
  extraData?: { [key: string | number]: unknown };
}

class NotificationService {
  public static async SendPushNotificationToUsername(
    payload: ISendPushNotificationToUsernamePayload
  ): Promise<AxiosResponse> {
    try {
      return await Axios.post<null, AxiosResponse>(`${backendUrl}/notification/sendtouser`, {
        ...payload,
        extraData: payload.extraData ? JSON.stringify(payload.extraData) : undefined
      });
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }
}

export default NotificationService;
