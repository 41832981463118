export interface ISaveApplicationRequestDTO {
  projectId: string;
  applicationName: string;
  applicationType: EApplicationType;
  baseUrl: string;
  pingUrl?: string;
  description: string;
}

export enum EApplicationType {
  Backend = 0,
  Frontend = 1,
  SchedulerJob = 2,
  Workflow = 3
}
