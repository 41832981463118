import { CheckResultType } from "../enum/auth";

export interface ILoginDto {
  username: string;
  password: string;
}
export interface IExternalTokenRequest {
  providerType: EExternalTokenProviderTypes;
  username: string;
  tokenString: string;
  refreshToken: string;
  redirectUri?: string;
  claims?: Record<string, string>;
  externalParameters?: Record<string, string>;
}

export enum EExternalTokenProviderTypes {
  None = 0,
  AzureAD = 1,
  Google = 2,
  Apple = 3,
  Facebook = 4
}

interface ICheckResult {
  resultType: CheckResultType;
  message: string;
}

interface ILoginResponse {
  /** UUID */
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  tokenString: string;
  refreshToken: string;
  language: string | null;
  forceResetPassword: boolean;
  passwordValidationResult?: {
    checkResults: ICheckResult[];
    hasWarnings: boolean;
    hasErrors: boolean;
    errors: string;
    warnings: string;
  };
}
