import { CodeResponse, useGoogleLogin } from "@react-oauth/google";
import { useHistory } from "react-router-dom";
import { EExternalTokenProviderTypes } from "../../models/dto/login.dto";
import { UserService } from "../../services/user-service";
import { KuikaAppManager } from "../utilty/kuika-app-manager";
import { KNavigator } from "./with-history";

const backendUrl = KuikaAppManager.GetBackendUrl();

export const withGoogleLogin = <T extends {} = any>(Component: any) => {
  return (props: any) => {
    const history: any = useHistory();
    const navigator = new KNavigator(history);

    const onSuccess = async (response: CodeResponse) => {
      const authWithGoogleResponse = await UserService.AuthenticateWithGoogle(response.code);

      if (authWithGoogleResponse.status !== 200) {
        console.error(authWithGoogleResponse);
        return;
      }

      const jwt = authWithGoogleResponse.data.id_token;
      const userInfo = JSON.parse(atob(jwt.split(".")[1]));
      await UserService.LoginWithExternalToken({
        providerType: EExternalTokenProviderTypes.Google,
        username: userInfo.email,
        tokenString: jwt,
        refreshToken: null,
        redirectUri: location.origin
      });

      localStorage.setItem("isGoogleLoginInProgress", "false");
    };

    const googleLogin = useGoogleLogin({
      flow: "auth-code",
      onSuccess,
      onError: (error) => {
        localStorage.setItem("isGoogleLoginInProgress", "false");
        localStorage.setItem("isGoogleLoginAborted", "true");
      },
      onNonOAuthError: (nonOAuthError) => {
        if (nonOAuthError.type === "popup_closed") {
          localStorage.setItem("isGoogleLoginInProgress", "false");
          localStorage.setItem("isGoogleLoginAborted", "true");
        }
      }
    });

    return <Component {...props} googleLogin={googleLogin} />;
  };
};
