import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import withCommonEvents from "../shared/hoc/with-common-events";

interface Props {
  dynamicStyles: string;
  className?: string;
  children: ReactNode;
}
const StyledComponent = styled.div.attrs((props) => ({
  className: props.className
}))`
  ${(props: any) => css`
    ${props.dynamicStyles}
  `}
`;

const DynamicStyleComponent: React.FC<Props> = ({ dynamicStyles, className, children }) => {
  return <StyledComponent>{children}</StyledComponent>;
};

export default withCommonEvents(DynamicStyleComponent);
