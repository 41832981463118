import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  HorizontalStack,
  Icon,
  KCol,
  KModal,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  TextInput,
  VerticalStack
} from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { FasonTakipLayout_Screen } from "../fasontakiplayout/fasontakiplayout";
import { HomePage_ScreenBase } from "./homepage-base";

import { ResimDetay_Screen } from "../resimdetay/resimdetay";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class HomePage_Screen extends HomePage_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <FasonTakipLayout_Screen
          form={this.props.form}
          initialValues={{ homepage_486104_value: "0" }}
          screenInputs={this.props.screenInputs}
          setMasterPageInitLoaded={this.setMasterPageInitLoaded}
          setMasterPageRecurringLoaded={this.setMasterPageRecurringLoaded}
          isMasterPageInitLoaded={this.state.isMasterPageInitLoaded}
          isMasterPageRecurringLoaded={this.state.isMasterPageRecurringLoaded}
        >
          <Spin spinning={this.state.spinnerCount > 0}>
            <div
              id="homepage_body"
              style={
                {
                  borderStyle: "solid",
                  borderTopWidth: 0,
                  borderRightWidth: 0,
                  borderBottomWidth: 0,
                  borderLeftWidth: 0,
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "left",
                  display: "block",
                  overflow: "auto",
                  minHeight: "calc(100vh - 80px - 60px - 0px - 0px)",
                  maxHeight: "calc(100vh - 80px - 60px - 0px - 0px)",
                  height: "calc(100vh - 80px - 60px - 0px - 0px)"
                } as any
              }
            >
              <KRow
                id="812015"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)"
                  } as any
                }
              >
                <KCol
                  id="837799"
                  onClick={(e?: any) => {
                    if (e && e.stopPropagation) e.stopPropagation();
                    this.HomePageComponent_837799_onClick();
                  }}
                  showCursorPointer
                  xs={24}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KRow
                    id="73881"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderTopLeftRadius: 14,
                        borderTopRightRadius: 14,
                        borderBottomRightRadius: 14,
                        borderBottomLeftRadius: 14,
                        backgroundColor: "rgba(171, 171, 171, 0.3)",
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="795766"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <KRow
                        id="673434"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 16,
                            paddingBottom: 8,
                            paddingLeft: 16,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="194167"
                          xs={16}
                          sm={16}
                          md={16}
                          lg={16}
                          xl={16}
                          xxl={16}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="625866"
                            value={ReactSystemFunctions.translate(this.ml, 625866, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          ></Label>
                        </KCol>

                        <KCol
                          id="921556"
                          xs={8}
                          sm={8}
                          md={8}
                          lg={8}
                          xl={8}
                          xxl={8}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-right",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Icon
                            id="88192"
                            iconName="work_outline"
                            style={
                              {
                                borderTopLeftRadius: 200,
                                borderTopRightRadius: 200,
                                borderBottomRightRadius: 200,
                                borderBottomLeftRadius: 200,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(69, 73, 199, 0.9)",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(255, 255, 255, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </KCol>
                      </KRow>

                      <KRow
                        id="286492"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 8,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="857374"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 16,
                              paddingRight: 16,
                              paddingBottom: 0,
                              paddingLeft: 16,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="724181"
                            value={this.state.ToplamYeniGelen?.at?.(0)?.yeniGelenToplam ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20pt",
                                color: "rgba(0, 0, 0, 1)"
                              } as any
                            }
                          ></Label>
                        </KCol>
                      </KRow>
                    </KCol>
                  </KRow>
                </KCol>

                <KCol
                  id="314655"
                  onClick={(e?: any) => {
                    if (e && e.stopPropagation) e.stopPropagation();
                    this.HomePageComponent_314655_onClick();
                  }}
                  showCursorPointer
                  xs={24}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KRow
                    id="934173"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderTopLeftRadius: 14,
                        borderTopRightRadius: 14,
                        borderBottomRightRadius: 14,
                        borderBottomLeftRadius: 14,
                        backgroundColor: "rgba(171, 171, 171, 0.3)",
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="834645"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <KRow
                        id="166650"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 16,
                            paddingBottom: 8,
                            paddingLeft: 16,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="396767"
                          xs={16}
                          sm={16}
                          md={16}
                          lg={16}
                          xl={16}
                          xxl={16}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="378348"
                            value={ReactSystemFunctions.translate(this.ml, 378348, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          ></Label>
                        </KCol>

                        <KCol
                          id="25699"
                          xs={8}
                          sm={8}
                          md={8}
                          lg={8}
                          xl={8}
                          xxl={8}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-right",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Icon
                            id="576705"
                            iconName="history_toggle_off"
                            style={
                              {
                                borderTopLeftRadius: 200,
                                borderTopRightRadius: 200,
                                borderBottomRightRadius: 200,
                                borderBottomLeftRadius: 200,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(213, 157, 88, 0.9)",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(255, 255, 255, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </KCol>
                      </KRow>

                      <KRow
                        id="721863"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 8,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="155214"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 16,
                              paddingRight: 16,
                              paddingBottom: 0,
                              paddingLeft: 16,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="462340"
                            value={this.state.ToplamBekleyen?.at?.(0)?.toplamBekleyen ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20pt",
                                color: "rgba(0, 0, 0, 1)"
                              } as any
                            }
                          ></Label>
                        </KCol>
                      </KRow>
                    </KCol>
                  </KRow>
                </KCol>

                <KCol
                  id="7632"
                  xs={24}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KRow
                    id="94576"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderTopLeftRadius: 14,
                        borderTopRightRadius: 14,
                        borderBottomRightRadius: 14,
                        borderBottomLeftRadius: 14,
                        backgroundColor: "rgba(171, 171, 171, 0.3)",
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="425316"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.HomePageComponent_425316_onClick();
                      }}
                      showCursorPointer
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <KRow
                        id="184610"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 16,
                            paddingBottom: 8,
                            paddingLeft: 16,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="312557"
                          xs={16}
                          sm={16}
                          md={16}
                          lg={16}
                          xl={16}
                          xxl={16}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="90595"
                            value={ReactSystemFunctions.translate(this.ml, 90595, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          ></Label>
                        </KCol>

                        <KCol
                          id="740304"
                          xs={8}
                          sm={8}
                          md={8}
                          lg={8}
                          xl={8}
                          xxl={8}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-right",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Icon
                            id="185661"
                            iconName="check_circle_outline"
                            style={
                              {
                                borderTopLeftRadius: 200,
                                borderTopRightRadius: 200,
                                borderBottomRightRadius: 200,
                                borderBottomLeftRadius: 200,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(122, 163, 97, 0.9)",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(255, 255, 255, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </KCol>
                      </KRow>

                      <KRow
                        id="991266"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 8,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="594917"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 16,
                              paddingRight: 16,
                              paddingBottom: 0,
                              paddingLeft: 16,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="26789"
                            value={this.state.ToplamTamamlanan?.at?.(0)?.toplamTamamlanan ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20pt",
                                color: "rgba(0, 0, 0, 1)"
                              } as any
                            }
                          ></Label>
                        </KCol>
                      </KRow>
                    </KCol>
                  </KRow>
                </KCol>

                <KCol
                  id="553606"
                  xs={24}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KRow
                    id="833027"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderTopLeftRadius: 14,
                        borderTopRightRadius: 14,
                        borderBottomRightRadius: 14,
                        borderBottomLeftRadius: 14,
                        backgroundColor: "rgba(171, 171, 171, 0.3)",
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="616780"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.HomePageComponent_616780_onClick();
                      }}
                      showCursorPointer
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <KRow
                        id="578311"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 16,
                            paddingBottom: 8,
                            paddingLeft: 16,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="990210"
                          xs={16}
                          sm={16}
                          md={16}
                          lg={16}
                          xl={16}
                          xxl={16}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="758461"
                            value={ReactSystemFunctions.translate(this.ml, 758461, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          ></Label>
                        </KCol>

                        <KCol
                          id="624879"
                          xs={8}
                          sm={8}
                          md={8}
                          lg={8}
                          xl={8}
                          xxl={8}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-right",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Icon
                            id="314033"
                            iconName="folder_open"
                            style={
                              {
                                borderTopLeftRadius: 200,
                                borderTopRightRadius: 200,
                                borderBottomRightRadius: 200,
                                borderBottomLeftRadius: 200,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(217, 20, 20, 0.9)",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(255, 255, 255, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </KCol>
                      </KRow>

                      <KRow
                        id="472498"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 8,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="91377"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 16,
                              paddingRight: 16,
                              paddingBottom: 0,
                              paddingLeft: 16,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="868529"
                            value={this.state.ToplamRedFire?.at?.(0)?.toplamRedFire ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20pt",
                                color: "rgba(0, 0, 0, 1)"
                              } as any
                            }
                          ></Label>
                        </KCol>
                      </KRow>
                    </KCol>
                  </KRow>
                </KCol>
              </KRow>

              <KRow
                id="756223"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)"
                  } as any
                }
              >
                <KCol
                  id="939306"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      textAlign: "-webkit-right",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <HorizontalStack
                    id="384121"
                    direction="horizontal"
                    size={12}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <Button
                      id="111686"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.HomePageComponent_111686_onClick();
                      }}
                      showCursorPointer
                      label={ReactSystemFunctions.translate(this.ml, 111686, "label", this.defaultML)}
                      size="middle"
                      loading={false}
                      ghost={false}
                      block={false}
                      htmlType="button"
                      icon="file_download"
                      iconPosition="left"
                      danger={false}
                      style={
                        {
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          borderBottomLeftRadius: 8,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingRight: 16,
                          paddingBottom: 8,
                          paddingLeft: 16,
                          width: "50px",
                          alignItems: "center",
                          textAlign: "-webkit-center",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "22px",
                          color: "rgba(0, 0, 0, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    ></Button>
                  </HorizontalStack>
                </KCol>
              </KRow>

              <KRow
                id="162676"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)"
                  } as any
                }
              >
                <KCol
                  id="304763"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KTable
                    id="25957"
                    kuikaRef={this.homepage_25957_value_kuikaTableRef}
                    form={this.props.form}
                    dataSource={this.state.FasoncuyaGonderilenlerListe}
                    size="middle"
                    bordered={true}
                    loading={false}
                    transformedOnMobileResolution={true}
                    showHeader={true}
                    fixedHeader={false}
                    editable={false}
                    tableWidthMode="fit"
                    globalSearch={true}
                    searchable={false}
                    sorter={true}
                    filtering={false}
                    pagination={true}
                    pageSize={10}
                    showSizeChanger={false}
                    columnChooser={false}
                    resizableColumns={false}
                    striped={true}
                    stripedColor="#F5F7FA"
                    insertRowActive={false}
                    insertRowPosition="top"
                    showNoDataFound={true}
                    nodatafoundmessage={ReactSystemFunctions.translate(
                      this.ml,
                      25957,
                      "nodatafoundmessage",
                      this.defaultML
                    )}
                    multiSelect={false}
                    export={false}
                    editableAlwaysActive={false}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KTableHeader
                      id="435252"
                      hideOnMobileResolution={false}
                      textDirection="Default"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "center",
                          textAlign: "-webkit-left"
                        } as any
                      }
                    ></KTableHeader>

                    <KTableRow
                      id="7089"
                      hoverFontColor="red"
                      hoverBgColor="#F5F5F5"
                      style={
                        {
                          borderTopWidth: 1,
                          borderRightWidth: 1,
                          borderBottomWidth: 1,
                          borderLeftWidth: 1,
                          borderColor: "rgba(218, 218, 218, 1)",
                          borderStyle: "solid",
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <KTableColumn
                        id="67680"
                        visibility={this.state.isComp67680Visible}
                        condition={(rowData) =>
                          ReactSystemFunctions.isEqualTo(this.state.GirisYapanKullanici[0]?.tip?.toString(), "true")
                        }
                        title={ReactSystemFunctions.translate(this.ml, 67680, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="266829"
                          value="[datafield:carI_ISIM]"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "11px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="485369"
                        title={ReactSystemFunctions.translate(this.ml, 485369, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="14036"
                          value="[datafield:tarih]"
                          formatter="date"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "11px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="556385"
                        title={ReactSystemFunctions.translate(this.ml, 556385, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <VerticalStack
                          id="392963"
                          direction="vertical"
                          size={1}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="538760"
                            value="[datafield:aciklama]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 700,
                                fontSize: "11px",
                                color: "rgba(245, 34, 45, 1)"
                              } as any
                            }
                          ></Label>

                          <Label
                            id="204768"
                            value="[datafield:isemrino]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "11px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          ></Label>
                        </VerticalStack>
                      </KTableColumn>

                      <KTableColumn
                        id="949097"
                        title={ReactSystemFunctions.translate(this.ml, 949097, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="706301"
                          value="[datafield:urunKodu]"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "11px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="396587"
                        title={ReactSystemFunctions.translate(this.ml, 396587, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="712622"
                          value="[datafield:urunAdi]"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "11px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="444508"
                        title={ReactSystemFunctions.translate(this.ml, 444508, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="187504"
                          value="[datafield:kT_FASON_KODU]"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "11px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="362519"
                        title={ReactSystemFunctions.translate(this.ml, 362519, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="990213"
                          value="[datafield:stoK_ADI]"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "11px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="272329"
                        title={ReactSystemFunctions.translate(this.ml, 272329, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="438665"
                          value="[datafield:hesaplananMiktar]"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "11px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="470238"
                        title={ReactSystemFunctions.translate(this.ml, 470238, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="541313"
                          value="[datafield:agirlik]"
                          formatter="money"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "11px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="243077"
                        title={ReactSystemFunctions.translate(this.ml, 243077, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="959065"
                          value="[datafield:birimfiyat]"
                          formatter="money"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "11px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="682843"
                        title={ReactSystemFunctions.translate(this.ml, 682843, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            width: "2px",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="161016"
                          direction="horizontal"
                          size={8}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="745743"
                            value="[datafield:tutar]"
                            formatter="money"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "11px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          ></Label>

                          <Label
                            id="451444"
                            value="[datafield:parabirimi]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "11px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>
                      </KTableColumn>

                      <KTableColumn
                        id="224475"
                        title={ReactSystemFunctions.translate(this.ml, 224475, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="309995"
                          value="[datafield:uretiM_ISEMRINO]"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "11px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="589453"
                        title={ReactSystemFunctions.translate(this.ml, 589453, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="566197"
                          value="[datafield:opisim]"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "11px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="728713"
                        title={ReactSystemFunctions.translate(this.ml, 728713, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="501940"
                          value="[datafield:opaciklama]"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "11px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="751003"
                        visibility={this.state.isComp751003Visible}
                        condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "0")}
                        title={ReactSystemFunctions.translate(this.ml, 751003, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="350228"
                          value="[datafield:yapilaN_ADET]"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "11px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="78527"
                        visibility={this.state.isComp78527Visible}
                        condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "0")}
                        title={ReactSystemFunctions.translate(this.ml, 78527, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="54432"
                          value="[datafield:yapilaN_KG]"
                          formatter="money"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "11px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="877597"
                        visibility={this.state.isComp877597Visible}
                        condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "0")}
                        title={ReactSystemFunctions.translate(this.ml, 877597, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="578803"
                          value="[datafield:kalaN_ADET]"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "11px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="559003"
                        visibility={this.state.isComp559003Visible}
                        condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "0")}
                        title={ReactSystemFunctions.translate(this.ml, 559003, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="309851"
                          value="[datafield:fire]"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "11px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></Label>
                      </KTableColumn>

                      <KTableColumn
                        id="756458"
                        title={ReactSystemFunctions.translate(this.ml, 756458, "title", this.defaultML)}
                        alwaysVisibleOnMobileResolution={false}
                        hideFiltering={false}
                        hideSorting={false}
                        hideSearch={false}
                        footerOptions="none"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="346835"
                          direction="horizontal"
                          size={8}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Icon
                            id="554809"
                            onClick={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.HomePageComponent_554809_onClick();
                            }}
                            showCursorPointer
                            iconName="image"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(229, 0, 25, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>

                          <Label
                            id="691547"
                            visibility={this.state.isComp691547Visible}
                            condition={(rowData) =>
                              ReactSystemFunctions.isEqualTo(this.state.GirisYapanKullanici[0]?.tip?.toString(), "true")
                            }
                            value="[datafield:saT_SIP_DURUM]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                width: "20px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(229, 0, 25, 1)"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>
                      </KTableColumn>
                    </KTableRow>
                  </KTable>
                </KCol>
              </KRow>

              <KRow
                id="617929"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)"
                  } as any
                }
              >
                <KCol
                  id="827298"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <Form.Item className="kFormItem" name="homepage_486104_value">
                    <TextInput
                      id="486104"
                      visibility={this.state.isComp486104Visible}
                      placeholder={ReactSystemFunctions.translate(this.ml, 486104, "placeholder", this.defaultML)}
                      allowClear={false}
                      bordered={true}
                      disabled={false}
                      type="text"
                      iconColor="rgba(0, 0, 0, 1)"
                      formatter="None"
                      isCharOnly={false}
                      autoComplete={true}
                      style={
                        {
                          borderTopLeftRadius: 4,
                          borderTopRightRadius: 4,
                          borderBottomRightRadius: 4,
                          borderBottomLeftRadius: 4,
                          borderTopWidth: 1,
                          borderRightWidth: 1,
                          borderBottomWidth: 1,
                          borderLeftWidth: 1,
                          borderColor: "rgba(218, 218, 218, 1)",
                          borderStyle: "solid",
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    ></TextInput>
                  </Form.Item>
                </KCol>
              </KRow>

              <KModal
                id="906115"
                visible={this.state.NavVisible906115}
                onOk={() => {
                  this.setState({ NavVisible906115: false }, () => {
                    this.callCallbackFunction(906115);
                  });
                }}
                onCancel={() => {
                  this.setState({ NavVisible906115: false }, () => {
                    this.callCallbackFunction(906115);
                  });
                }}
                closable={true}
                width="600px"
                footer={null}
                height="400px"
                children={
                  this.state.NavVisible906115 && (
                    <ResimDetay_Screen
                      onClose={() => {
                        this.setState({ NavVisible906115: false }, () => {
                          this.callCallbackFunction(906115);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
              ></KModal>
            </div>
          </Spin>
        </FasonTakipLayout_Screen>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(HomePage_Screen)))))
);
export { tmp as HomePage_Screen };
//export default tmp;
//export { tmp as HomePage_Screen };
