import { Guid } from "guid-typescript";
import { AppBootstrapper } from "../bootstrapper";
import HelperService from "./helper-service";
import { UserService } from "./user-service";

const ONE_SECOND = 1000;

enum ESessionTimeoutEventType {
  RESET_INACTIVITY_COUNTDOWN = 0,
  LOGGED_OUT = 1,
  NEW_INSTANCE_JOINED = 2
}

interface IResetCountdownMessage {
  instance: string;
  type: ESessionTimeoutEventType;
}

class SessionService {
  private uniqueInstanceIdentifier: Guid = Guid.create();

  private broadcast: BroadcastChannel | null = null;

  private INACTIVITY_TICK = ONE_SECOND;

  private MICRO_INACTIVITY_THRESHOLD = ONE_SECOND;

  private inactivityIntervalId: Guid | null;

  private eventActivityMap: { [key: string]: boolean } = {};

  private eventActivityTimeoutMap: { [key: string]: NodeJS.Timeout | null } = {};

  private _inactivityCountdown: number;

  private isResettingInactivityCountdownPaused: boolean = false;

  private lastUpdatedRemainingSessionTime: number = 0;

  private isLoggingOut: boolean = false;

  constructor(
    private readonly bootstrapper: AppBootstrapper,
    private readonly inactivityThresholdAsSeconds: number,
    private readonly logoutWarningThreshold: number
  ) {
    this.resetInactivityCountdown();
  }

  /**
   * We can't afford to verify if the user is logged in or not
   * by sending a request to the backend every time an event is
   * triggered.
   *
   * So we will use the tokenString to verify if the user is
   * logged in or not.
   * */
  static getIsConsideredLoggedIn(): boolean {
    return !!localStorage.getItem("tokenString");
  }

  get inactivityCountdown(): number {
    return this._inactivityCountdown;
  }

  set inactivityCountdown(value: number) {
    this._inactivityCountdown = value;

    if (this.bootstrapper?.state?.isMounted) {
      this.bootstrapper.updateRemainingSessionTime(value);
    }

    // In order to threshold to be valid, it must be greater than 0.
    if (this.logoutWarningThreshold > 0 && value <= this.logoutWarningThreshold) {
      this.isResettingInactivityCountdownPaused = true;
    }
  }

  public resetInactivityCountdown(): void {
    this.inactivityCountdown = this.inactivityThresholdAsSeconds;
    this.isResettingInactivityCountdownPaused = false;
  }

  private onEvent(e: Event, broadcastMessage: boolean = true): void {
    if (!SessionService.getIsConsideredLoggedIn()) return;

    if (!this.isResettingInactivityCountdownPaused) {
      this.resetInactivityCountdown();
    }

    this.eventActivityMap[e.type] = true;

    if (this.eventActivityTimeoutMap[e.type]) {
      clearTimeout(this.eventActivityTimeoutMap[e.type]);
      this.eventActivityTimeoutMap[e.type] = null;
    }

    this.eventActivityTimeoutMap[e.type] = setTimeout(() => {
      this.eventActivityMap[e.type] = false;
      this.eventActivityTimeoutMap[e.type] = null;
    }, this.MICRO_INACTIVITY_THRESHOLD);

    if (!this.isResettingInactivityCountdownPaused && broadcastMessage) {
      this.broadCastMessage({
        instance: this.uniqueInstanceIdentifier.toString(),
        type: ESessionTimeoutEventType.RESET_INACTIVITY_COUNTDOWN
      });
    }
  }

  /**
   * Touch events are a bit different from the other events
   * as they start with a touchstart event and end with a
   * touchend event.
   *
   * So we need to keep track if the user is touching the screen
   * or not as touching but not firing any touchmove events still
   * counts as activity.
   */
  private onTouchStart(e: TouchEvent): void {
    this.eventActivityMap.isTouching = true;
    this.onEvent(e);
  }

  private onTouchMove(e: TouchEvent): void {
    this.onEvent(e);
  }

  private onTouchEnd(e: TouchEvent): void {
    this.eventActivityMap.isTouching = false;
    this.onEvent(e);
  }

  /**
   * Returns if any of the registered events are been triggered _in_ the last
   * {inactivityThresholdAsSeconds} amount of seconds.
   */
  private getIsInactive(): boolean {
    return Object.values(this.eventActivityMap).some((v: boolean) => !v);
  }

  private async handleInactivityCountdown(): Promise<void> {
    if (!SessionService.getIsConsideredLoggedIn()) return;
    if (this.isLoggingOut) return;

    if (this.inactivityCountdown <= 0) {
      this.isLoggingOut = true;

      this.broadCastMessage({
        instance: this.uniqueInstanceIdentifier.toString(),
        type: ESessionTimeoutEventType.LOGGED_OUT
      });

      await UserService.Logout();
      this.isLoggingOut = false;
      this.resetInactivityCountdown();
      HelperService.gotoLogin();
      return;
    }

    if (!this.getIsInactive() && !this.isResettingInactivityCountdownPaused) {
      return;
    }

    if (Date.now() - this.lastUpdatedRemainingSessionTime >= ONE_SECOND) {
      this.inactivityCountdown -= 1;
      this.lastUpdatedRemainingSessionTime = Date.now();
    }
  }

  private setInterval(id?: Guid): void {
    const identifier: Guid = id ?? Guid.create();

    if (this.inactivityIntervalId && !this.inactivityIntervalId.equals(identifier)) {
      return;
    }

    if (!this.inactivityIntervalId) {
      this.inactivityIntervalId = identifier;
    }

    HelperService.setCorrectTimer(
      this.INACTIVITY_TICK,
      24,
      () => {},
      () => {
        this.handleInactivityCountdown();

        if (this.inactivityCountdown >= 0) {
          this.setInterval(identifier);
        }
      }
    );
  }

  public async onClickLogout(broadcast: boolean = true): Promise<void> {
    if (broadcast) {
      this.broadCastMessage({
        instance: this.uniqueInstanceIdentifier.toString(),
        type: ESessionTimeoutEventType.LOGGED_OUT
      });
    }
    this.resetInactivityCountdown();

    this.isLoggingOut = true;
    await UserService.Logout();
    this.isLoggingOut = false;
    HelperService.gotoLogin();
  }

  private broadCastMessage(message: IResetCountdownMessage): void {
    if (this.broadcast) {
      this.broadcast.postMessage(message);
    }
  }

  private onBroadcastMessage(e: MessageEvent): void {
    const message: IResetCountdownMessage = e.data;

    if (message.instance === this.uniqueInstanceIdentifier.toString()) {
      return;
    }

    if (message.type === ESessionTimeoutEventType.NEW_INSTANCE_JOINED) {
      this.resetInactivityCountdown();
    }

    if (message.type === ESessionTimeoutEventType.RESET_INACTIVITY_COUNTDOWN) {
      if (this.isResettingInactivityCountdownPaused) {
        this.resetInactivityCountdown();
      } else {
        this.onEvent(e, false);
      }
    }

    if (message.type === ESessionTimeoutEventType.LOGGED_OUT) {
      this.onClickLogout(false);
    }
  }

  public registerEventListeners(): void {
    /**
     * Destroy any existing event listeners
     * before registering new ones in case
     * of this method being called multiple times
     * for some reason.
     */
    this.destroyEventListeners();
    document.addEventListener("mousemove", this.onEvent.bind(this));
    document.addEventListener("focus", this.onEvent.bind(this));
    document.addEventListener("keypress", this.onEvent.bind(this));
    document.addEventListener("touchstart", this.onTouchStart.bind(this));
    document.addEventListener("touchmove", this.onTouchMove.bind(this));
    document.addEventListener("touchend", this.onTouchEnd.bind(this));
    window.addEventListener("scroll", this.onEvent.bind(this));
    this.setInterval();

    if (BroadcastChannel) {
      this.broadcast = new BroadcastChannel("session-channel");
      this.broadcast.addEventListener("message", this.onBroadcastMessage.bind(this));

      this.broadCastMessage({
        instance: this.uniqueInstanceIdentifier.toString(),
        type: ESessionTimeoutEventType.NEW_INSTANCE_JOINED
      });
    }

    // This is to sync the countdown between bootstrapper and this service.
    this.resetInactivityCountdown();
  }

  public destroyEventListeners(): void {
    document.removeEventListener("mousemove", this.onEvent.bind(this));
    document.removeEventListener("focus", this.onEvent.bind(this));
    document.removeEventListener("keypress", this.onEvent.bind(this));
    document.removeEventListener("touchstart", this.onTouchStart.bind(this));
    document.removeEventListener("touchmove", this.onTouchMove.bind(this));
    document.removeEventListener("touchend", this.onTouchEnd.bind(this));
    window.removeEventListener("scroll", this.onEvent.bind(this));

    if (this.broadcast) {
      this.broadcast.removeEventListener("message", this.onBroadcastMessage.bind(this));
      this.broadcast.close();
      this.broadcast = null;
    }

    this.inactivityIntervalId = null;
  }
}

export default SessionService;
