
export class KuikaHelper {
  public static addExtraHeaderForApiCall(request: any, url: string, backendUrl: string) {
    switch (url) {
      
      default:
        break;
    }
  }
}


