import { Component } from "react";
import { CommonProps } from "../../components/web-components/common/common-props";

const withCommonEvents = <P, S>(WrappedComponent) => {
  class CommonEventComponent<P, S> extends Component<P & CommonProps, S> {
    constructor(props: any) {
      super(props);
    }

    handleClick = (e: any) => {
      if (this.props.onClick && typeof this.props.onClick === "function") {
        this.props.onClick(e, this.props.rowIndex);
      }
    };

    render() {
      if (this.props.visibility !== "hidden")
        return this.applyEditablty(
          <WrappedComponent<P & CommonProps, S>
            {...(this.props as P)}
            onClick={this.handleClick}
            ref={this.props.kuikaRef}
            tabIndex={this.props.editability === "disabled" ? -1 : undefined}
          />
        );

      return <></>;
    }

    applyEditablty = (child: any) => {
      if (this.props.editability === "disabled" && child?.props?.style) {
        const style: any = {};
        Object.assign(style, child.props.style);
        style.pointerEvents = "none";
        style.opacity = 0.7;
        child = {
          ...child,
          props: {
            ...child.props,
            style
          }
        };
      }

      return child;
    };
  }

  return CommonEventComponent;
};

export default withCommonEvents;
