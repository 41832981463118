import CryptoJS from "crypto-js";

export class CryptoJSHelper {
  private static getBase64CryptoParamsForLogin() {
    const base64EncSettings = {
      password: "tK5UTui+DPh8lIlBxya5XVsmeDCoUl6vHhdIESMB6sQ=",
      salt: "QWlGNHNhMTJTQWZ2bGhpV3U=",
      iv: "bVQzNFNhRkQ1Njc4UUFaWA=="
    };
    const salt = CryptoJS.enc.Base64.parse(base64EncSettings.salt);
    const key = CryptoJS.PBKDF2(base64EncSettings.password, salt, { keySize: 256 / 32, iterations: 10000 });
    const iv = CryptoJS.enc.Base64.parse(base64EncSettings.iv);
    return { key, iv };
  }

  public static encryptToAESAndEncode(raw) {
    const { key, iv } = this.getBase64CryptoParamsForLogin();
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(raw), key, { iv });
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  }
}
